import React, { useState, useContext, useEffect } from 'react'
import { Container, Button, Box } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-mui'
import { toast } from 'react-toastify'

import { Context as AuthContext } from '@/Context/AuthContext'
import * as Styles from './Dashboard.styles'
import studentSchema from '@/utils/schemas/studentSchema'
import { createStudent } from '@/api/students'
import { getUserPopulate } from '@/api/users'
import CardStudent from '@/components/Students/CardStudent/CardStudent'
import Loading from '@/components/Commons/Loading/Loading'

const Dashboard = () => {
  const { user } = useContext(AuthContext)
  const [registerStudentOpen, setRegisterStudentOpen] = useState(false)
  const [userStudents, setUserStudents] = useState([])
  const initialValues = {
    nome: '',
    ra: ''
  }

  const handleOpenRegisterStudent = () => {
    setRegisterStudentOpen(true)
  }

  const handleCloseRegisterStudent = () => {
    setRegisterStudentOpen(false)
  }

  const handleSubmit = async (values) => {
    try {
      await createStudent({ ...values, user_id: user.id })

      toast.success("Aluno cadastrado com sucesso", {
        position: toast.POSITION.TOP_RIGHT
      })
      handleCloseRegisterStudent()
    } catch (err) {
      toast.error("RA ja foi cadastrado, verifique se digitou corretamente", {
        position: toast.POSITION.TOP_RIGHT
      })
    }
  }

  const handleGetUserPopulate = async (userId) => {
    const { students } = await getUserPopulate(userId)

    setUserStudents(students)
  }

  useEffect(() => {
    if (user.id) {
      handleGetUserPopulate(user.id)
    }
  }, [user])

  return (
    <>
      <Container>
        <Styles.WelcomeContainer>
          <Styles.Title>Bem vindo</Styles.Title>
          <Styles.Subtitle>
            Cadastre seu filho para acompanhar os gastos e créditos na cantina
            Flor do ipê
          </Styles.Subtitle>
        </Styles.WelcomeContainer>

        <Button
          color="primary"
          size="large"
          type="submit"
          variant="contained"
          onClick={handleOpenRegisterStudent}
        >
          Cadastrar novo aluno
        </Button>

        <Styles.StudentsContainer>
          <Styles.Title>Alunos Cadastrados</Styles.Title>
          {userStudents &&
            userStudents.map((student) => (
              <CardStudent key={student.id} student={student} />
            ))}
          {userStudents.length === 0 && <p>Nenhum aluno foi cadastrado</p>}
          {!userStudents && <Loading />}
        </Styles.StudentsContainer>
      </Container>

      <Dialog open={registerStudentOpen} onClose={handleCloseRegisterStudent}>
        <DialogTitle>Cadastrar novo aluno</DialogTitle>
        <DialogContent>
          <p>
            Cadastre seu filho usando o nome completo e <br />o número de
            matricula (RM)
          </p>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={studentSchema}
          >
            <Styles.WrapperForm>
              <Form>
                <Field
                  width={100}
                  fullWidth
                  component={TextField}
                  label="Nome"
                  name="nome"
                  variant="outlined"
                />
                <Box height="16px"></Box>
                <Field
                  width={100}
                  fullWidth
                  component={TextField}
                  label="RA"
                  name="ra"
                  variant="outlined"
                />
                <Button
                  color="primary"
                  size="large"
                  type="submit"
                  variant="contained"
                  fullWidth
                  css={{
                    marginTop: '16px'
                  }}
                >
                  Cadastrar aluno
                </Button>
              </Form>
            </Styles.WrapperForm>
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default Dashboard
