import styled from '@emotion/styled'
import { colors } from '@mui/material'

interface BasicProps {
  color: string
}

export const Wrapper = styled.div<BasicProps>`
  align-items: center;
  background-color: ${colors.common.white};
  border-left: 3px solid ${({ color }) => color};
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
  padding: 24px 32px;
  position: relative;
`

export const Left = styled.div`
  display: flex;
`

export const Icon = styled.div<BasicProps>`
  align-items: center;
  background-color: ${({ color }) => color}20;
  border-radius: 50%;
  border: 1px solid ${({ color }) => color};
  display: flex;
  height: 40px;
  justify-content: center;
  width: 40px;
`

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
`

export const Right = styled.p<BasicProps>`
  color: ${({ color }) => color};
  font-weight: 600;
`
