import styled from '@emotion/styled'

import { MINIMUM_SCREEN_SIZE_TABLET } from '@/utils/constants'

export const WrapperInputs = styled.form`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: ${MINIMUM_SCREEN_SIZE_TABLET}px) {
    flex-direction: row;
  }
`

export const Left = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 8px;
  
  @media (min-width: ${MINIMUM_SCREEN_SIZE_TABLET}px) {
    margin-bottom: 0px;
    width: calc(50% - 8px);
  }
`

export const Right = styled.div`
  display: flex;
  width: 100%;
  
  @media (min-width: ${MINIMUM_SCREEN_SIZE_TABLET}px) {
    width: calc(50% - 8px);
  }
`
