import * as Yup from 'yup'

const resetPasswordSchema = Yup.object({
  password: Yup.string()
    .required("Digite a senha")
    .min(8, 'A senha dever ter no mínimo 8 caracteres')
    .max(16, 'A senha dever ter no máximo 16 caracteres'),
  passwordConfirmation: Yup.string()
    .min(8, 'A senha dever ter no mínimo 8 caracteres')
    .max(16, 'A senha dever ter no máximo 16 caracteres')
    .oneOf([Yup.ref("password"), null], "Senhas não são iguais")
    .required("Required")
})

export default resetPasswordSchema
