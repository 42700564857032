import React from 'react'
import {
  Container,
  Box,
  Button,
  TextField as TextFieldMasked
} from '@mui/material'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-mui'
import NumberFormat from 'react-number-format'
import { toast } from 'react-toastify'

import ButtonBack from '@/components/Commons/Buttons/ButtonBack/ButtonBack'
import * as Styles from './SignUp.styles'
import signUpSchema from '@/utils/schemas/signUpSchema'
import { registerUser } from '@/api/users'
import history from '@/history'

const SignUp = () => {
  const initialValues = {
    username: '',
    email: '',
    cpf: '',
    password: ''
  }

  const handleSubmit = async (values) => {
    try {
      await registerUser(values)

      history.push('/login')
      toast.success("Usuário cadastrado com sucesso", {
        position: toast.POSITION.TOP_RIGHT
      })
    } catch (error) {
      const errorMessage = error.response?.data?.error?.message

      if(errorMessage == 'Email already taken') {
        toast.error("Esse e-mail ja foi cadastrado", {
          position: toast.POSITION.TOP_RIGHT
        })
      } else if(errorMessage == 'This attribute must be unique') {
        toast.error("CPF ja cadastrado", {
          position: toast.POSITION.TOP_RIGHT
        })
      } else{
        toast.error("Erro ao criar sua conta", {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    }
  }

  return (
    <Container>
      <Box marginBottom={2}
        marginTop={4}>
        <ButtonBack />
      </Box>
      <Box>
        <Styles.Title>Criar nova conta</Styles.Title>
        <Styles.Subtitle>
          Cadastre uma nova conta para acompanhar as contas na cantina flor do
          ipê
        </Styles.Subtitle>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={signUpSchema}
        >
          <Form>
            <Styles.WrapperForm>
              <Field
                width={100}
                component={TextField}
                label="Nome"
                name="username"
                variant="outlined"
              />
              <Field
                width={100}
                component={TextField}
                label="E-mail"
                name="email"
                type="email"
                variant="outlined"
              />
              <Field
                component={TextField}
                label="Senha"
                name="password"
                type="password"
                variant="outlined"
              />
              <Field name="cpf">
                {({
                  field,
                  form: { setFieldValue, setFieldTouched },
                  meta: { error, touched }
                }) => (
                  <NumberFormat
                    customInput={TextFieldMasked}
                    format="###.###.###-##"
                    label="CPF"
                    defaultValue={' '}
                    disabled={false}
                    variant="outlined"
                    value={field.value}
                    error={touched && !!error}
                    helperText={touched && !!error && error}
                    onBlur={() => {
                      setFieldTouched(field.name, true)
                    }}
                    onValueChange={(values) =>
                      setFieldValue('cpf', values.value)
                    }
                  />
                )}
              </Field>
              <Button
                color="primary"
                size="large"
                type="submit"
                variant="contained"
              >
                Cadastrar-se
              </Button>
            </Styles.WrapperForm>
          </Form>
        </Formik>
      </Box>
    </Container>
  )
}

export default SignUp
