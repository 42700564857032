import { colors } from "@mui/material"

export const TableCell = {
  background: colors.blue[400],
  color: colors.common.white
}

export const SortLabel = {
  color: colors.common.white
}
