import React, { useContext, useEffect, useRef, useState } from 'react'
import { useParams } from "react-router-dom"
import {
  Button,
  Typography,
  colors
} from '@mui/material'
import { Container } from '@mui/system'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import { toast } from 'react-toastify'

const INITIAL_TYPE_PAY = 'Credit'

import history from '@/history'
import { getStudents, editStudent } from '@/api/students'
import { deleteOrder, getOrders, postOrder } from '@/api/orders'
import { getProducts } from '@/api/products'
import { convertToReal } from '@/utils/services/money-service'
import { Context as AuthContext } from '@/Context/AuthContext'
import ButtonBack from '@/components/Commons/Buttons/ButtonBack/ButtonBack'
import Timeline from '@/components/Commons/Timeline/Timeline'
import TopPage from '@/components/Commons/TopPage/TopPage'
import PayModal from '@/components/Students/PayModal/PayModal'
import * as Styles from './Id.styles'

const Id = () => {
  const isFirst = useRef(true)

  const { user } = useContext(AuthContext)
  const [loading, setLoading] = useState(true)
  const [displayModal, setDisplayModal] = useState(false)
  const [studentsData, setStudentsData] = useState()
  const [ordersData, setOrdersData] = useState()
  const [metaOrders, setMetaOrders] = useState()
  const [productsData, setProductsData] = useState()
  const [pageOrders, setPageOrders] = useState(1)
  const [value, setValue] = useState('')
  const [typePay, setTypePay] = useState(INITIAL_TYPE_PAY)
  const [product, setProduct] = useState('select')

  const params = useParams()

  const handleGetOrders = async (page = 1) => {
    const paramsGetOrders = {
      filters: {
        student_id: {
          '$eq': params.id
        }
      },
      populate: 'product',
      sort: 'createdAt:DESC',
      pagination: {
        page,
        pageSize: 10
      }
    }

    return await getOrders(paramsGetOrders)
  }

  const handleGetStudentsInfos = async () => {
    try {
      setLoading(true)

      const paramsGetStudents = {
        populate: '*',
        filters: {
          ra: {
            '$eq': params.id
          }
        }
      }

      const studentsReq = getStudents(paramsGetStudents)

      const productsRes = getProducts()

      const [students, orders, products] = await Promise.all([
        studentsReq,
        handleGetOrders(),
        productsRes
      ])

      if (students?.data?.length === 0) {
        history.push('/notfound')

        return
      }

      setStudentsData(students?.data[0])

      setOrdersData(orders?.data)

      setMetaOrders(orders?.meta)

      setProductsData(products?.data)
    } catch {
      toast.error("Ocorreu um erro", {
        position: toast.POSITION.TOP_RIGHT
      })
    }

    setLoading(false)
  }

  const handleTypePay = (
    event,
    type
  ) => {
    if (type) {
      setTypePay(type)
    }
  }

  const handleChangeProduct = (event) => {
    setProduct(event.target.value)
  }

  const handleConfirm = async (e) => {
    e.preventDefault()

    if(!value) {
      toast.error("Preencha o valor", {
        position: toast.POSITION.TOP_RIGHT
      })

      return
    }

    try {
      setLoading(true)

      if (typePay === 'Credit') {
        const dataOrder = {
          data: {
            value: Number(value),
            student_id: params?.id,
            order_type: 'Credit'
          }
        }

        const dataStudent = {
          data: {
            saldo: Number(value) + Number(studentsData?.saldo)
          }
        }

        await postOrder(dataOrder)

        await editStudent(dataStudent, studentsData?.id)

        toast.success("Crédito adicionado com sucesso", {
          position: toast.POSITION.TOP_RIGHT
        })
      }

      if(typePay === 'Debit') {
        if(product === 'select') {
          toast.error("Selecione uma categoria", {
            position: toast.POSITION.TOP_RIGHT
          })

          return
        }

        const dataOrder = {
          data: {
            value: Number(value),
            student_id: params?.id,
            order_type: 'Debit',
            product: product
          }
        }

        const dataStudent = {
          data: {
            saldo: Number(studentsData?.saldo) - Number(value)
          }
        }

        await postOrder(dataOrder)

        await editStudent(dataStudent, studentsData?.id)

        toast.success("Débito adicionado com sucesso", {
          position: toast.POSITION.TOP_RIGHT
        })
      }

      await handleGetStudentsInfos()

      setValue('')

      setDisplayModal(false)

      setPageOrders(1)

      setProduct('select')

      setTypePay('Credit')
    } catch {
      toast.error("Erro ao cadastrar, tente novamente", {
        position: toast.POSITION.TOP_RIGHT
      })
    } finally {
      setLoading(false)
    }
  }

  const handleChangePage = async (page) => {
    setLoading(true)

    try {
      const orders = await handleGetOrders(page)

      const newOrders = [
        ...ordersData,
        ...orders.data
      ]

      setOrdersData(newOrders)

    } catch {
      toast.error("Ocorreu um erro ao buscar", {
        position: toast.POSITION.TOP_RIGHT
      })
    }

    setLoading(false)
  }

  const handleCancelOrder = async (infos) => {
    if(infos.order_type === 'Debit') {
      try {
        const dataStudent = {
          data: {
            saldo: Number(studentsData.saldo) + Number(infos.value)
          }
        }

        await editStudent(dataStudent, studentsData.id)

        await deleteOrder(infos.id)

        toast.success("Ordem apagada com sucesso", {
          position: toast.POSITION.TOP_RIGHT
        })
      } catch {
        toast.error("Ocorreu um erro ao apagar o pedido", {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    }

    if(infos.order_type === 'Credit') {
      try {
        const dataStudent = {
          data: {
            saldo: Number(studentsData.saldo) - Number(infos.value)
          }
        }

        await editStudent(dataStudent, studentsData.id)

        await deleteOrder(infos.id)

        toast.success("Pedido apagado com sucesso", {
          position: toast.POSITION.TOP_RIGHT
        })
      } catch {
        toast.error("Ocorreu um erro ao apagar o pedido", {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    }

    await handleGetStudentsInfos()
  }

  useEffect(() => {
    if (user.id) {
      handleGetStudentsInfos()
    }
  }, [user]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(isFirst.current || pageOrders === 1) {
      isFirst.current = false

      return
    }

    handleChangePage(pageOrders)
  }, [pageOrders]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Container>
        <TopPage>
          <ButtonBack />
        </TopPage>
        <Styles.Wrapper>
          <Styles.Left>
            <Styles.WrapperStudent>
              <Typography
                fontSize="1.125rem"
                fontWeight="bold"
                variant="h2"
              >
                {studentsData?.nome}
              </Typography>
              <Typography
                fontWeight="bold"
                variant="body2"
                color="gray"
              >
                RA: {studentsData?.ra}
              </Typography>
            </Styles.WrapperStudent>
            <Styles.WrapperCards>
              <Styles.WrapperCard>
                <Styles.CardInfo
                  color={studentsData?.saldo < 0 ? colors.red[400] : colors.green[400]}
                  icon={(
                    <AttachMoneyIcon
                      color={studentsData?.saldo < 0 ? "error" : "success"}
                      fontSize="small"
                    />
                  )}
                  info={convertToReal(studentsData?.saldo || 0)}
                  title="SALDO"
                />
                {user?.role?.type == 'superadmin' && (
                  <Button
                    color="success"
                    fullWidth
                    onClick={() => setDisplayModal(true)}
                    variant="contained"
                  >
                    Adicionar valor
                  </Button>
                )}
              </Styles.WrapperCard>
            </Styles.WrapperCards>
          </Styles.Left>
          <Styles.Right>
            <Timeline
              items={ordersData}
              onCancelOrder={handleCancelOrder}
            />

            {pageOrders < metaOrders?.pagination?.pageCount && (
              <Button
                color="inherit"
                onClick={() => setPageOrders(pageOrders + 1)}
                sx={{ margin: '10px auto 0 auto', display: 'flex' }}
                variant="outlined"
              >
                Ver mais
              </Button>
            )}
          </Styles.Right>
        </Styles.Wrapper>
      </Container>
      <PayModal
        product={product}
        displayModal={displayModal}
        onChangeCategory={handleChangeProduct}
        onChangeTypePay={handleTypePay}
        onChangeValue={setValue}
        onCloseModal={() => setDisplayModal(false)}
        onConfirm={handleConfirm}
        loading={loading}
        products={productsData}
        typePay={typePay}
        value={value}
      />
    </>
  )
}

export default Id
