import React, { useContext, useRef, useState } from 'react'
import CheckIcon from '@mui/icons-material/Check'
import Checkbox from '@mui/material/Checkbox'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import IconButton from '@mui/material/IconButton'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

import { Context } from '@/Context/AuthContext'
import * as Styles from './MenuItem.styles'

interface MenuItemProps {
  checkedItems: number[]
  editItems: number[]
  item: {
    id: number
    title: string
  }
  onEditItem: (_data: {
    title: string
    prevTitle: string
  }, _id: number) => undefined
  onToggleCheckedItem: (_id: number, _typeAction: string) => undefined
  onToggleDialogDelete: (_id: number[], _displayDialog: boolean) => undefined
}

const MenuItem: React.FC<MenuItemProps> = ({
  checkedItems = [],
  editItems = [],
  item,
  onEditItem = () => undefined,
  onToggleCheckedItem = () => undefined,
  onToggleDialogDelete = () => undefined
}) => {
  const { user } = useContext(Context)

  const labelId = `checkbox-list-label-${item.id}`

  const input = useRef()

  const [value, setValue] = useState(item.title)

  const handleOnChange = (e: any) => {
    setValue(e.target.value)
  }

  const handleOnSubmitEdit = (e: any) => {
    e.preventDefault()

    const data = {
      title: value,
      prevTitle: item.title
    }

    onEditItem(data, item.id)
  }

  return (
    <form onSubmit={handleOnSubmitEdit}>
      <ListItem
        key={item.title}
        disablePadding

        secondaryAction={user?.role?.type == 'superadmin' && (
          <>
            {editItems.indexOf(item.id) !== -1 && (
              <IconButton
                aria-label="check"
                edge="end"
                onClick={handleOnSubmitEdit}
                title="Confirmar"
              >
                <CheckIcon color="success" />
              </IconButton>
            )}
            {editItems.indexOf(item.id) !== -1 && (
              <IconButton
                aria-label="clear"
                edge="end"
                onClick={onToggleCheckedItem(item.id, 'EDIT')}
                title="Cancelar edição"
              >
                <ClearOutlinedIcon color="disabled" />
              </IconButton>
            )}
            {editItems.indexOf(item.id) === -1 && (
              <IconButton
                aria-label="edit"
                edge="end"
                onClick={onToggleCheckedItem(item.id, 'EDIT')}
                title="Editar"
              >
                <EditIcon color="primary" />
              </IconButton>
            )}
            <IconButton
              aria-label="delete"
              edge="end"
              title="Apagar"
              onClick={() => onToggleDialogDelete([item.id], true)}
            >
              <DeleteIcon color="error" />
            </IconButton>
          </>
        )}
      >
        <ListItemButton
          onClick={onToggleCheckedItem(item.id, 'CHECK')}
          role={undefined}
          sx={user?.role?.type !== 'superadmin' ? { '&:hover': {
            backgroundColor: 'transparent',
            cursor: 'initial'
          } } : {}}
          disableRipple={user?.role?.type !== 'superadmin'}
        >
          {user?.role?.type == 'superadmin' && (
            <ListItemIcon>
              <Checkbox
                checked={checkedItems.indexOf(item.id) !== -1}
                disableRipple
                edge="start"
                inputProps={{ 'aria-labelledby': labelId }}
                tabIndex={-1}
              />
            </ListItemIcon>
          )}
          {editItems.indexOf(item.id) === -1 && (
            <ListItemText
              id={labelId}
              primary={item.title}
            />
          )}
        </ListItemButton>
        {user?.role?.type == 'superadmin' && (
          editItems.indexOf(item.id) !== -1 && (
            <Styles.Input
              id="edit"
              inputRef={input}
              label="Editar"
              onChange={handleOnChange}
              size="small"
              value={value}
              variant="outlined"
            />
          )
        )}
      </ListItem>
    </form>
  )
}

export default MenuItem
