import apiClient from '.'

const AUTH_URL = '/auth/local'

const FORGOT_PASS = '/auth/forgot-password'

const RESET_PASS = '/auth/reset-password'

export const auth = (data) => {
  return apiClient.post(AUTH_URL, {
    ...data
  })
}

export const forgotPassword = (data) => {
  return apiClient.post(FORGOT_PASS, data)
}

export const resetPassword = (data) => {
  return apiClient.post(RESET_PASS, data)
}
