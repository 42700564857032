import React from 'react'
import { Router } from 'react-router-dom'
import { colors, GlobalStyles } from '@mui/material'
import '@/assets/styles/global.css'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'

import Routes from '@/routes'
import history from '@/history'
import { AuthProvider } from '@/Context/AuthContext'
import Header from '@/components/Header/Header'

function App() {
  return (
    <AuthProvider>
      <GlobalStyles
        styles={{
          body: {
            backgroundColor: `${colors.grey[50]}`,
            fontFamily: 'Nunito'
          }
        }}
      />
      <Header />
      <ToastContainer />
      <Router history={history}>
        <Routes />
      </Router>
    </AuthProvider>
  )
}

export default App
