import React from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Button, colors } from '@mui/material'

import history from '@/history'

const ButtonBack = () => {
  const handleBack = () => {
    history.goBack()
  }

  return (
    <Button
      sx={{
        fontWeight: 'bold',
        color: `${colors.common.black}`
      }}
      onClick={() => handleBack()}
    >
      <ArrowBackIcon />
      Voltar
    </Button>
  )
}

export default ButtonBack
