import styled from '@emotion/styled'
import List from '@mui/material/List'
import { colors } from '@mui/material'

export const MenuList = styled(List)`
  width: 100%;
  background-color: ${colors.common.white};
  margin-bottom: 10px;
`

export const WrapperDay = styled.div``

export const Title = styled.h3``
