import React, { useMemo } from 'react'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { colors } from '@material-ui/core'

const ROWS_PER_PAGE = 500

import { IStudents } from '@/@types/models/Students'
import { SORTED_ASCENDING, SORTED_DESCENDING } from '@/utils/constants'
import { convertToReal } from '@/utils/services/money-service'
import history from '@/history'

type Order = typeof SORTED_ASCENDING | typeof SORTED_DESCENDING;

interface BasicTableBodyProps {
  order: Order
  orderBy: keyof IStudents
  page: number
  rows: IStudents[]
}

function descendingComparator<IStudents> (a: IStudents, b: IStudents, orderBy: keyof IStudents) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }

  if (b[orderBy] > a[orderBy]) {
    return 1
  }

  return 0
}

function getComparator<Key extends keyof IStudents> (
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string }, // eslint-disable-line no-unused-vars
  b: { [key in Key]: number | string } // eslint-disable-line no-unused-vars
) => number {
  return order === SORTED_DESCENDING
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

// eslint-disable-next-line no-unused-vars
function stableSort<T> (array: readonly IStudents[], comparator: (a: IStudents, b: IStudents) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [IStudents, number])

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])

    if (order !== 0) {
      return order
    }

    return a[1] - b[1]
  })

  return stabilizedThis.map((el) => el[0])
}

const BasicTableBody: React.FC<BasicTableBodyProps> = ({
  order,
  orderBy,
  page,
  rows = []
}) => {
  const rowsMemo = useMemo(() => rows.map((row) => { return { ...row, nome: row.nome.toUpperCase() }}), [rows])

  return (
    <TableBody>
      {stableSort(rowsMemo, getComparator(order, orderBy))
        .slice(page * ROWS_PER_PAGE, page * ROWS_PER_PAGE + ROWS_PER_PAGE)
        .map((row) => (
          <TableRow
            hover
            key={row.ra}
            onClick={() => history.push(`/students/${row.ra}`)}
          >
            <TableCell>{row.ra}</TableCell>
            <TableCell>{row.nome}</TableCell>
            <TableCell sx={{
              color: row?.saldo < 0 ? colors.red[500] : colors.green[500]
            }}
            >
              {row.saldo ? convertToReal(row.saldo) : '-'}
            </TableCell>
          </TableRow>
        ))}
      {rowsMemo.length === 0 ? (
        <TableRow>
          <TableCell>Nenhum aluno encontrado</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </TableRow>
      ) : null}
    </TableBody>
  )
}

export default BasicTableBody
