import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'

import * as Styles from './Loading.style'

const Loading = () => (
  <Styles.Wrapper>
    <CircularProgress />
  </Styles.Wrapper>
)

export default Loading
