import styled from '@emotion/styled'
import { TextField } from '@mui/material'

import { MINIMUM_SCREEN_SIZE_TABLET } from '@/utils/constants'

export const Input = styled(TextField)`
  left: 40px;
  position: absolute;
  width: calc(100% - 140px);

  @media (min-width: ${MINIMUM_SCREEN_SIZE_TABLET}px) {
    left: 70px;
    width: calc(100% - 180px);
  }
`
