import React, { useMemo } from 'react'
import { colors, Typography } from '@mui/material'
import { deserialise } from 'kitsu-core'

import TimelineCard from './TimelineCard/TimelineCard'
import { getJustDate } from '@/utils/services/date-service'
import * as Styles from './Timeline.styles'

const Timeline = ({ onCancelOrder = () => undefined, items = [] }) => {
  const itemsLayout = useMemo(() => {
    return [...items].map(item => {
      return {
        ...item,
        product: deserialise(item?.product)
      }
    })
  }, [items])

  return (
    <>
      {itemsLayout.map((item, index) => {
        return (
          <Styles.Wrapper key={`TimelineCard-${index}`}>
            <Styles.Date>
              {getJustDate(item.createdAt)}
            </Styles.Date>

            <TimelineCard
              onCancelOrder={onCancelOrder}
              infos={item}
            />
          </Styles.Wrapper>
        )
      })}

      {itemsLayout.length === 0 ? (
        <Typography
          color={colors.grey[500]}
          variant="body2"
          sx={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          Nenhum pedido feito
        </Typography>
      ) : null}
    </>
  )
}

export default Timeline
