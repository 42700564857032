import React, { useContext, useMemo } from 'react'
import { Button, colors, Typography } from '@mui/material'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import DeleteIcon from '@mui/icons-material/Delete'

import { convertToReal } from '@/utils/services/money-service'
import { Context as AuthContext } from '@/Context/AuthContext'
import * as Styles from './TimelineCard.style'

const CREDIT = 'Credit'
const DEBIT = 'Debit'

interface ItemPros {
  createdAt: string
  id: number
  order_type: string
  product: {
    data?: {
      name: string
    }
  }
  student_id: string
  value: number
}

interface TimelineCardProps {
  infos: ItemPros
  onCancelOrder: (_: any) => void
  type: typeof CREDIT | typeof DEBIT
  value: string | number
}

const TimelineCard: React.FC<TimelineCardProps> = ({
  infos,
  onCancelOrder = () => undefined
}) => {
  const { user } = useContext(AuthContext)

  const color = useMemo(() => {
    if (infos.order_type === CREDIT) {
      return colors.green[200]
    } else if (infos.order_type === DEBIT) {
      return '#FA403E'
    }

    return colors.grey[200]
  }, [infos.order_type])

  const diffDates = useMemo(() => {
    const dateCard = new Date(infos.createdAt).getTime()
    const currentDate = new Date().getTime()

    return Number(((currentDate - dateCard) / 3.6e+6).toFixed())
  }, [infos.createdAt])

  return (
    <Styles.Wrapper color={color}>
      <Styles.Left>
        <Styles.Icon color={color}>
          <AttachMoneyIcon
            color={infos.order_type === CREDIT ? 'success' : 'warning'}
          />
        </Styles.Icon>
        <Styles.Text>
          {infos.order_type === DEBIT ? (
            <Typography
              fontWeight="bold"
              variant="body1"
            >
              {infos.product.data?.name}
            </Typography>
          ) : null}
          <Typography
            color={colors.grey[400]}
            variant="caption"
          >
            {infos.order_type === CREDIT ? 'CRÉDITO' : 'DÉBITO'}
          </Typography>
        </Styles.Text>
      </Styles.Left>

      <Styles.Right color={color}>
        {infos.order_type === CREDIT ? '' : '-'}{convertToReal(infos.value)}
      </Styles.Right>
      {(diffDates <= 24 && user?.role?.type === 'superadmin') ? (
        <Button
          sx={{
            maxHeight: '30px',
            maxWidth: '30px',
            minHeight: '30px',
            minWidth: '30px',
            position: 'absolute',
            right: '4px',
            top: '4px',
            color: 'red'
          }}
          onClick={() => onCancelOrder({
            order_type: infos.order_type,
            value: infos.value,
            id: infos.id,
            student_id: infos.student_id
          })}
          size="medium"
        >

          <DeleteIcon
            fontSize="small"
            color="disabled"
          />
        </Button>
      ) : null}

    </Styles.Wrapper>
  )
}

export default TimelineCard
