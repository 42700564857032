import React from 'react'
import { Box } from '@mui/material'

import notFound from '@/assets/images/notFound.svg'

const NotFound = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80vh'
      }}
    >
      <img src={notFound} />
    </Box>
  )
}

export default NotFound
