import React, { useContext } from 'react'
import { Container } from '@mui/material'

import { Context } from '@/Context/AuthContext'
import HeaderItem from './HeaderItem/HeaderItem'
import MenuDropdown from './MenuDropdown/MenuDropdown'
import * as Styles from './Header.styles'
import logo from '@/assets/images/logo.jpg'

const menuItems = [
  {
    href: '/menu',
    title: 'Cardápio'
  },
  {
    href: '/dashboard',
    title: 'Alunos'
  },
  {
    href: '/deposito',
    title: 'Depósito'
  }
]

const Header = () => {
  const { authenticated, handleLogout } = useContext(Context)

  return (
    <Styles.Header>
      <Container>
        <Styles.Wrapper>
          <Styles.Logo src={logo} />
          {authenticated && (
            <>
              <Styles.Right>
                {menuItems.map(item => (
                  <HeaderItem
                    key={item.title}
                    href={item.href}
                    sx={{ marginRight: '16px' }}
                  >
                    {item.title}
                  </HeaderItem>
                ))}
                <HeaderItem
                  onClick={handleLogout}
                >
                  Sair
                </HeaderItem>
              </Styles.Right>

              <MenuDropdown
                items={menuItems}
                onLogout={handleLogout}
              />
            </>
          )}
        </Styles.Wrapper>
      </Container>
    </Styles.Header>
  )
}

export default Header
