export default (strCPF: any) => {
  let soma = 0,
    resto

  const blacklist = [...Array(10)].map((_, index) =>
    [...Array(11)].map(() => index).join('')
  )

  if (blacklist.includes(strCPF)) {
    return false
  }

  for (let i = 1; i <= 9; i++) {
    soma = soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i)
  }

  resto = (soma * 10) % 11

  if (resto === 10 || resto === 11) {
    resto = 0
  }

  if (resto !== parseInt(strCPF.substring(9, 10))) {
    return false
  }

  soma = 0

  for (let i = 1; i <= 10; i++) {
    soma = soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i)
  }

  resto = (soma * 10) % 11

  if (resto === 10 || resto === 11) {
    resto = 0
  }

  if (resto !== parseInt(strCPF.substring(10, 11))) {
    return false
  }

  return true
}
