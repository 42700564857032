import styled from '@emotion/styled'

export const Wrapper = styled.div`
  display: flex;

  @media(max-width: 576px){
    display: block;
  }
`

export const Title = styled.h2`
  color: #7bafdf;
`

export const Text = styled.p`
  color: #959595;
  line-height: 16px;
  margin-bottom: 30px;
`

export const Pix = styled.h2`
  color: #959595;
  line-height: 16px;
  margin-bottom: 30px;
  text-align: center;
`

export const Box = styled.div`
  width: 50%;

  @media(max-width: 576px){
    width: 100%;
  }
`

export const Link = styled.a`
  color: #FFF;
  text-decoration: none;
`
