import styled from '@emotion/styled'
import { colors } from '@mui/material'

import { MINIMUM_SCREEN_SIZE_TABLET } from '@/utils/constants'

export const Header = styled.header`
  align-items: center;
  background-color: ${colors.common.white};
  border-bottom: 1px solid ${colors.grey[200]};
  display: flex;
  height: 80px;
  justify-content: center;
`

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`

export const Right = styled.div`
  display: none;

  @media (min-width: ${MINIMUM_SCREEN_SIZE_TABLET}px) {
    display: flex;
  }
`

export const Logo = styled.img`
  width: 50px;
`
