import styled from "@emotion/styled"
import { colors } from "@mui/material"

const GAP_COLUMN = 30

import {
  MINIMUM_SCREEN_SIZE_DESKTOP,
  MINIMUM_SCREEN_SIZE_TABLET
} from '@/utils/constants'
import BasicCardInfo from '@/components/Commons/CardInfo/CardInfo'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${MINIMUM_SCREEN_SIZE_TABLET}px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
`

export const Left = styled.div`
  margin-bottom: 24px;
  position: sticky;
  top: 10px;
  z-index: 999;
  
  @media (min-width: ${MINIMUM_SCREEN_SIZE_TABLET}px) {
    top: 20px;
    width: calc(50% - ${GAP_COLUMN/2}px);
  }

  @media (min-width: ${MINIMUM_SCREEN_SIZE_DESKTOP}px) {
    width: calc(60% - ${GAP_COLUMN/2}px);
  }
`

export const WrapperStudent = styled.div`
  background-color: ${colors.common.white};
  border-radius: 4px;
  padding: 24px 20px;
  margin-bottom: 10px;
`

export const WrapperCards = styled.div`
  @media (min-width: ${MINIMUM_SCREEN_SIZE_TABLET}px) {
    display: flex;
    justify-content: space-between;
  }
`

export const WrapperCard = styled.div`
  @media (min-width: ${MINIMUM_SCREEN_SIZE_TABLET}px) {
    width: 100%;
    max-width: 340px;
  }
`

export const CardInfo = styled(BasicCardInfo)`
  margin-bottom: 4px;
`

export const Right = styled.div`
  padding-bottom: 20px;
  @media (min-width: ${MINIMUM_SCREEN_SIZE_TABLET}px) {
    width: calc(50% - ${GAP_COLUMN/2}px);
  }

  @media (min-width: ${MINIMUM_SCREEN_SIZE_DESKTOP}px) {
    width: calc(40% - ${GAP_COLUMN/2}px);
  }
`
