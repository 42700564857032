import styled from '@emotion/styled'

export const Title = styled.h2`
  color: #7bafdf;
`

export const Subtitle = styled.p`
  color: #959595;
  line-height: 16px;
`

export const WrapperForm = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  max-width: 350px;
  row-gap: 16px;
  width: 100%;

  @media(max-width: 576px){
    max-width: 100%;
  }
`
