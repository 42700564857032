import apiClient from '.'

export const STUDENTS_URL = '/students'

export const getStudents = (params, cancelToken) => {
  return apiClient.get(STUDENTS_URL, {
    params,
    cancelToken
  })
}

export const createStudent = (data) => {
  return apiClient.post(STUDENTS_URL, { data })
}

export const editStudent = (params, idUser) => {
  return apiClient.put(`${STUDENTS_URL}/${idUser}`, {
    ...params
  })
}
