import styled from '@emotion/styled'
import { colors } from '@material-ui/core'

export const Card = styled.div`
  background: #FFF;
  width: 100%;
  border-radius: 3px;
  margin-bottom: 18px;
  padding: 15px;
  display: flex;
  align-items: center;
  column-gap: 30px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

  @media(max-width: 576px){
    display: block;
  }
`

export const CardTitle = styled.h2`
  color: #000;
`

export const CardSubtitle = styled.h5`
  color: #959595;
  margin-top: 5px;
`

export const CardSaldo = styled.p`
 color: ${props => (props.saldo <= 0 ? colors.red[500] : colors.green[500])};
 font-weight: 700;
 margin-top: 18px;
`
