import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

export const Navigator = styled(Link)`
  color: #959595;
  cursor: pointer;
  line-height: 16px;
  text-decoration: none;
  
  &:hover{
    color: #7bafdf;
  }
`
