import styled from '@emotion/styled'
import { colors } from "@mui/material"

interface BasicProps {
  color: string
}

export const Wrapper = styled.div<BasicProps>`
  align-items: center;
  background-color: ${colors.common.white};
  border-radius: 4px;
  border: 1px solid ${({ color }) => color};
  display: flex;
  justify-content: flex-start;
  padding: 16px 20px;
  width: 100%;
`

export const Icon = styled.div<BasicProps>`
  align-items: center;
  background-color: ${({ color }) => `${color}10`};
  border-radius: 50%;
  border: 1px solid ${({ color }) => color};
  display: flex;
  height: 40px;
  justify-content: center;
  margin-right: 16px;
  width: 40px;
`

export const Right = styled.div<BasicProps>`
  display: flex;
  flex-direction: column;
`
