import * as Yup from 'yup'

import validateCPF from '@/utils/validate/validateCPF'

const loginSchema = Yup.object({
  username: Yup.string().required('O nome é obrigatório'),
  email: Yup.string().email('Insira um e-mail válido').required('O e-mail é obrigatório'),
  password: Yup.string().required('Insira sua senha'),
  cpf: Yup.string().required('O CPF é obrigatório').default('').test('cpf', 'CPF Inválido', (value) => {
    const documentLength = !!value && String(value).length

    if(documentLength < 11){
      return false
    }

    if(documentLength === 11 && validateCPF(value)){
      return true
    }

    return false
  })
})

export default loginSchema
