import React, { useContext, useEffect, useState } from 'react'
import { Container, Typography } from '@mui/material'
import { toast } from 'react-toastify'

const DELETE_ERROR_MESSAGE = 'Erro ao deletar'

import { Context as ContextAuth } from '@/Context/AuthContext'
import { deleteItemMenuById, editItemMenu, getMenu, postItem } from '@/api/menu'
import Loading from '@/components/Commons/Loading/Loading'
import MenuWrapper from '@/components/Menu/MenuWrapper'
import TopPage from '@/components/Commons/TopPage/TopPage'

const Menu = () => {

  const { user } = useContext(ContextAuth)

  const [items, setItems] = useState([])

  const [checkedItems, setCheckedItems] = useState([])

  const [editItems, setEditItems] = useState([])

  const [displayDialogDelete, setDisplayDialogDelete] = useState(false)

  const [loading, setLoading] = useState(false)

  const handleGetMenu = async () => {
    try {
      setLoading(true)

      const data = (await getMenu()).data

      setItems(data)
    } catch {
      toast.error("Erro ao buscar os itens", {
        position: toast.POSITION.TOP_RIGHT
      })
    } finally {
      setLoading(false)
    }
  }

  const reset = () => {
    setItems([])

    setCheckedItems([])

    setEditItems([])

    handleGetMenu()
  }

  const handleToggle = (value, typeAction) => () => {
    let currentIndex = null
    let newItems = []

    if(typeAction === 'CHECK') {
      currentIndex = checkedItems.indexOf(value)

      newItems = [...checkedItems]
    } else {
      currentIndex = editItems.indexOf(value)

      newItems = [...editItems]
    }

    if (currentIndex === -1) {
      newItems.push(value)
    } else {
      newItems.splice(currentIndex, 1)
    }

    typeAction === 'CHECK' ? setCheckedItems(newItems) : setEditItems(newItems)
  }

  const handleAddItem = async (data) => {
    if(!data.title) {
      toast.error("Preencha o valor", {
        position: toast.POSITION.TOP_RIGHT
      })

      return
    }

    try {
      setLoading(true)

      await postItem(data)

      toast.success("Adicionado com sucesso", {
        position: toast.POSITION.TOP_RIGHT
      })

      reset()
    } catch {
      toast.error("Erro ao adicionar", {
        position: toast.POSITION.TOP_RIGHT
      })
    } finally {
      setLoading(false)
    }
  }

  const handleDeleteItem = async (ids) => {
    const reqsDeleteItems = Promise.all([ids.map(id => deleteItemMenuById(id))])

    try {
      setLoading(true)

      await reqsDeleteItems

      toast.success("Item(ns) deletado(s) com sucesso", {
        position: toast.POSITION.TOP_RIGHT
      })
    } catch(error) {
      toast.error(DELETE_ERROR_MESSAGE, {
        position: toast.POSITION.TOP_RIGHT
      })
    } finally {
      reset()

      setLoading(false)
    }
  }

  const handleEditItem = async (data, id) => {
    if(!data.title) {
      toast.error("Preencha o valor", {
        position: toast.POSITION.TOP_RIGHT
      })

      return
    }

    if(data.title === data.prevTitle) {
      toast.error("O valor não foi alterado", {
        position: toast.POSITION.TOP_RIGHT
      })

      return
    }

    try {
      setLoading(true)

      await editItemMenu(data, id)

      toast.success("Editado com sucesso", {
        position: toast.POSITION.TOP_RIGHT
      })

      reset()
    } catch {
      toast.error("Erro ao editar", {
        position: toast.POSITION.TOP_RIGHT
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (user.id) {
      handleGetMenu()
    }
  }, [user]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      <TopPage>
        <Typography
          fontSize="1.125rem"
          fontWeight="bold"
          variant="h2"
        >
          Cardápio da semana
        </Typography>
      </TopPage>
      <MenuWrapper
        checkedItems={checkedItems}
        displayDialogDelete={displayDialogDelete}
        editItems={editItems}
        items={items}
        onAddItem={handleAddItem}
        onDeleteItem={handleDeleteItem}
        onDisplayDialogDelete={setDisplayDialogDelete}
        onEditItem={handleEditItem}
        onToggleCheckedItem={handleToggle}
      />
      {loading && <Loading />}
    </Container>
  )
}

export default Menu
