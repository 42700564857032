import React from 'react'
import { Button, colors, Modal, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import * as Styles from './BasicModal.style'

interface ModalProps {
  children: React.ReactNode
  display: boolean
  loading: boolean
  onClose: () => void
  onConfirm: () => void
  title: string
}

const BasicModal: React.FC<ModalProps> = ({
  children = null,
  display = false,
  loading = false,
  onClose = () => undefined,
  onConfirm = () => undefined,
  title = ''
}) => (
  <Modal
    open={display}
    onClose={onClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Styles.Wrapper>
      <Styles.Header>
        <Typography
          fontWeight="bold"
          variant="body1"
        >
          {title}
        </Typography>
        <Button
          sx={{
            borderRadius: '50%',
            color: `${colors.common.black}`,
            height: 40,
            position: 'absolute',
            right: 16,
            width: 40,
            minWidth: 40
          }}
          onClick={onClose}
          color="info"
        >
          <CloseIcon color="action" />
        </Button>
      </Styles.Header>
      <Styles.Body>
        {children}
      </Styles.Body>
      <Styles.Footer>
        <Button
          color="info"
          onClick={onClose}
          variant="outlined"
          sx={{
            width: 'calc(50% - 8px)'
          }}
        >
            Cancelar
        </Button>
        <Button
          color="success"
          disabled={loading}
          onClick={onConfirm}
          variant="contained"
          sx={{
            width: 'calc(50% - 8px)'
          }}
        >
            Confirmar
        </Button>
      </Styles.Footer>
    </Styles.Wrapper>
  </Modal>
)

export default BasicModal
