import styled from '@emotion/styled'

import { MINIMUM_SCREEN_SIZE_DESKTOP } from '@/utils/constants'

export const Form = styled.form`
  display: flex;
  margin-top: 20px;
  margin-bottom: 80px;
  flex-direction: column;

  @media (min-width: ${MINIMUM_SCREEN_SIZE_DESKTOP}px) {
    flex-direction: row;
  }
`

export const WrapperDay = styled.div`
  margin: 16px 0;
  
  @media (min-width: ${MINIMUM_SCREEN_SIZE_DESKTOP}px) {
    flex-direction: row;
    margin: 0 16px;
  }
`

export const DialogBody = styled.div`
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;

  button {
    width: calc(50% - 10px);
  }
`
