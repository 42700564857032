import apiClient from "."

const USERS_URL = "/users"

export const getUser = () => {
  return apiClient.get("/users/me")
}

export const getUserPopulate = (idUser) => {
  return apiClient.get(`${USERS_URL}/${idUser}?populate=*`)
}

export const registerUser = (data) => {
  const newData = { ...data, confirmed: true }

  return apiClient.post(USERS_URL, newData)
}
