import apiClient from "."

export const MENUS_URL = "/menus"

export const getMenu = (params) => {
  return apiClient.get(MENUS_URL, {
    params
  })
}

export const editItemMenu = (data, id) => {
  return apiClient.put(`${MENUS_URL}/${id}`, {
    data
  })
}

export const deleteItemMenuById = (id) => {
  return apiClient.delete(`${MENUS_URL}/${id}`)
}

export const postItem = (data) => {
  return apiClient.post(MENUS_URL, {
    data
  })
}

export const postInsta = (data) => {
  return apiClient.post('/insta', {
    data
  })
}
