import styled from '@emotion/styled'
import { colors } from '@mui/material'

import { MINIMUM_SCREEN_SIZE_TABLET } from '@/utils/constants'

export const Wrapper = styled.div`
  background-color: ${colors.common.white};
  left: 50%;
  max-width: 500px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
`

export const Header = styled.div`
  align-items: center;
  border-bottom: 1px solid ${colors.grey[200]};
  display: flex;
  font-weight: 600;
  justify-content: center;
  padding: 20px 0;
`

export const Body = styled.div`
  padding: 16px 16px 8px 16px;
  
  @media (min-width: ${MINIMUM_SCREEN_SIZE_TABLET}px) {
    padding: 16px 32px;
  }
`

export const Footer = styled.div`
  padding: 16px;
  display: flex;
  justify-content: space-between;

  @media (min-width: ${MINIMUM_SCREEN_SIZE_TABLET}px) {
    padding: 16px 32px;
  }
`
