import React, { useMemo, useState } from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import TablePagination from '@mui/material/TablePagination'

import { IMeta, IStudents } from '@/@types/models'
import {
  INITIAL_VALUE_ORDER_BY,
  SORTED_ASCENDING,
  SORTED_DESCENDING
} from '@/utils/constants'
import BasicTableHead from './BasicTableHead/BasicTableHead'
import BasicTableBody from './BasicTableBody/BasicTableBody'

type Order = typeof SORTED_ASCENDING | typeof SORTED_DESCENDING;

interface BasicTableProps {
  meta: IMeta | undefined
  onChangePage: (_: any, _page: number) => void
  page: number
  rows: IStudents[] | undefined
}

const BasicTable: React.FC<BasicTableProps> = ({
  meta,
  rows = [],
  page,
  onChangePage = () => undefined
}) => {
  const [order, setOrder] = useState<Order>(SORTED_ASCENDING)

  const [orderBy, setOrderBy] = useState<keyof IStudents>(INITIAL_VALUE_ORDER_BY)

  const students = useMemo(() => rows, [rows])

  const handleRequestSort = (_: unknown, property: keyof IStudents) => {
    const isAsc = orderBy === property && order === SORTED_ASCENDING

    setOrder(isAsc ? SORTED_DESCENDING : SORTED_ASCENDING)

    setOrderBy(property)
  }

  return (
    <Paper sx={{ width: '100%' }}>
      <TableContainer>
        <Table
          sx={{ minWidth: 768 }}
          aria-labelledby="tableTitle"
        >
          <BasicTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <BasicTableBody
            order={order}
            orderBy={orderBy}
            page={page}
            rows={students}
          />
        </Table>
      </TableContainer>
    </Paper>
  )
}

export default BasicTable
