import React, { useContext } from "react"
import { Switch, Route, Redirect } from "react-router-dom"

import { Context } from '@/Context/AuthContext'
import Dashboard from "@/screens/Dashboard/Dashboard"
import ForgotPassword from "@/screens/ForgotPassword/ForgotPassword"
import Deposito from "@/screens/Deposito/Deposito"
import Id from '@/screens/Students/Id/Id'
import Login from '@/screens/Login/Login'
import Menu from '@/screens/Menu/Menu'
import NotFound from "@/components/Commons/NotFound/NotFound"
import ResetPassword from '@/screens/ResetPassword/ResetPassword'
import SignUp from "@/screens/SignUp/SignUp"
import Students from '@/screens/Students/Students'
import InstaLogin from '@/screens/InstaLogin/InstaLogin'

export default function Routes() {
  const { user } = useContext(Context)
  function CustomRoute({ isPrivate, ...rest }) {
    const { authenticated } = useContext(Context)

    if (isPrivate && !authenticated) {
      return <Redirect to="/login" />
    }

    return <Route {...rest} />
  }
  return (
    <Switch>
      <CustomRoute
        component={Login}
        exact
        path="/login"
      />
      <CustomRoute
        component={Login}
        exact
        path="/"
      />
      <CustomRoute
        component={Menu}
        exact
        path="/menu"
      />
      <CustomRoute
        component={InstaLogin}
        path="/insta-teste"
      />
      <CustomRoute
        component={Id}
        exact
        path="/students/:id"
      />
      <CustomRoute
        component={user?.role?.type == 'superadmin' ? Students : Dashboard }
        exact
        isPrivate
        path="/dashboard"
      />
      <CustomRoute
        component={ForgotPassword}
        exact
        path="/forgot-password"
      />
      <CustomRoute
        component={Deposito}
        exact
        path="/deposito"
      />
      <CustomRoute
        component={ResetPassword}
        exact
        path="/reset-password"
      />
      <CustomRoute
        component={SignUp}
        exact
        path="/sign-up"
      />
      <CustomRoute
        component={NotFound}
        path="*"
      />
    </Switch>
  )
}
