import React, { useContext, useEffect } from 'react'
import { Button, CircularProgress, Container } from '@mui/material'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-mui'

import { Context } from '@/Context/AuthContext'
import { ReactComponent as LoginImage } from '@/assets/images/loginImage.svg'
import loginSchema from '@/utils/schemas/loginSchema'
import history from '@/history'
import LinkRoute from '@/components/Commons/LinkRoute/LinkRoute'
import * as Styles from './Login.styles'

interface LoginValues {
  identifier: string
  password: string
}

const Login = () => {
  const { handleLogin, loading, authenticated } = useContext(Context)

  const initialValues: LoginValues = { identifier: '', password: '' }

  const handleSubmit = (values: LoginValues) => {
    handleLogin(values)
  }

  useEffect(() => {
    if (authenticated) {
      history.push('/dashboard')
    }
  }, [authenticated])

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={loginSchema}
    >
      <Form>
        <Container>
          <Styles.Wrapper>
            <Styles.Left>
              <Styles.Title>Bem vindo!</Styles.Title>
              <Styles.Subtitle>
                A plataforma da cantina Flor do Ipê chegou <br />
                para facilitar a sua vida!
              </Styles.Subtitle>
              <Styles.WrapperForm>
                <Field
                  component={TextField}
                  disabled={loading}
                  label="E-mail"
                  name="identifier"
                  type="email"
                  variant="outlined"
                />
                <Field
                  component={TextField}
                  disabled={loading}
                  label="Senha"
                  name="password"
                  type="password"
                  variant="outlined"
                />
                <LinkRoute route="/forgot-password">
                  Esqueceu a senha?
                </LinkRoute>
                <Button
                  color="primary"
                  disabled={loading}
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  {loading ? <CircularProgress size="1.6rem" /> : 'Entrar'}
                </Button>
                <LinkRoute route="sign-up">
                  Não tem uma conta? Clique aqui para cadastrar
                </LinkRoute>
              </Styles.WrapperForm>
            </Styles.Left>
            <Styles.Right>
              <LoginImage />
            </Styles.Right>
          </Styles.Wrapper>
        </Container>
      </Form>
    </Formik>
  )
}

export default Login
