import React, { useContext, useEffect } from 'react'
import { Button, colors, Container, Typography, TextField } from "@mui/material"
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'

import { useStudents } from '@/Context/hooks'
import Loading from '@/components/Commons/Loading/Loading'
import { Context as ContextAuth } from '@/Context/AuthContext'
import CardInfo from '@/components/Commons/CardInfo/CardInfo'
import Table from '@/components/Commons/BasicTable/BasicTable'
import TopPage from '@/components/Commons/TopPage/TopPage'
import * as Styles from './Students.style'

const Students = () => {
  const {
    displayResetSearchButton,
    getAll,
    loading,
    meta,
    page,
    reset,
    search,
    setPage,
    setSearch,
    students
  } = useStudents()

  const { user } = useContext(ContextAuth)

  const handleChangePage = (_, page) => {
    setPage(page)
  }

  useEffect(() => {
    if(user.id) {
      getAll()
    }
  }, [page, user, getAll])

  return (
    <Container>
      <Styles.Wrapper>
        <TopPage>
          <Typography
            fontSize="1.125rem"
            fontWeight="bold"
            variant="h2"
          >
            Alunos
          </Typography>
        </TopPage>
        <Styles.WrapperInfos>
          <Styles.Left>
            <TextField
              id="outlined-basic"
              label="Pesquisar alunos"
              variant="outlined"
              placeholder="Digite um termo para pequisar"
              fullWidth={true}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Styles.Left>
          <Styles.Right>
            <CardInfo
              color={colors.blue[400]}
              title="ALUNOS"
              icon={(
                <PeopleAltIcon
                  color="info"
                  fontSize="small"
                />
              )}
              info={meta?.pagination.total}
            />
          </Styles.Right>
        </Styles.WrapperInfos>
        <Styles.Wrapper>
          <Styles.WrapperInfosResults>
            <Typography
              fontWeight="semibold"
              variant="body2"
              sx={Styles.Title}
            >
              Foram encontrados
              <Styles.TitleCount>
                {meta?.pagination.total}
              </Styles.TitleCount>
              resultados
            </Typography>
            {displayResetSearchButton && (
              <Button
                onClick={reset}
                variant="contained"
              >
                Limpar pesquisa
              </Button>
            )}
          </Styles.WrapperInfosResults>
          <Table
            meta={meta}
            onChangePage={handleChangePage}
            page={page}
            rows={students}
          />
        </Styles.Wrapper>
      </Styles.Wrapper>
      {loading && <Loading />}
    </Container>
  )
}

export default Students
