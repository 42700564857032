export const ERROR_DEFAULT = 'Ocorreu um erro. Tente novamente mais tarde.'

export const ERROR_GET_STUDENTS = 'Erro ao buscar alunos. Tente novamente mais tarde.'

export const SUCCESS_SEND_MAIL_FORGOT_PASS = 'Pronto! Verifique sua caixa de entrada no e-mail.'

export const DOES_EXIST_EMAIL = 'Este email não está cadastrado.'

export const SUCCESS_RESET_PASSWORD = 'Senha alterada com sucesso. Faça o login!'

export const INVALID_TOKEN_RESET_PASSWORD = 'Senha alterada com sucesso. Faça o login!'
