import { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

const LOGIN_SUCCESS_MESSAGE = 'Logado com sucesso'

const LOGOUT_MESSAGE = 'Deslogado com sucesso'

const LOGIN_ERROR_MESSAGE = 'Informações de login Incorretas'
import apiClient from '@/api'
import { auth } from '@/api/auth'
import { getUser } from '@/api/users'
import history from '@/history'

export default function useAuth() {

  const [authenticated, setAuthenticated] = useState(false)

  const [user, setUser] = useState({})

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const token = localStorage.getItem('token')

    if (token && token !== 'undefined') {
      apiClient.defaults.headers.Authorization = `Bearer ${JSON.parse(token)}`

      setAuthenticated(true)

      handleGetUser()
    }

    setLoading(false)
  }, [])

  async function handleLogin(data) {
    setLoading(true)

    try {
      const { jwt } = await auth(data)

      localStorage.setItem('token', JSON.stringify(jwt))

      apiClient.defaults.headers.Authorization = `Bearer ${jwt}`

      handleGetUser()

      setAuthenticated(true)

      toast.success(LOGIN_SUCCESS_MESSAGE, {
        position: toast.POSITION.TOP_RIGHT
      })
    } catch {
      toast.error(LOGIN_ERROR_MESSAGE, {
        position: toast.POSITION.TOP_RIGHT
      })
    } finally {
      setLoading(false)
    }
  }

  function handleLogout() {
    setAuthenticated(false)

    setUser({})

    localStorage.removeItem('token')

    apiClient.defaults.headers.Authorization = undefined

    history.push('/login')

    toast.success(LOGOUT_MESSAGE, {
      position: toast.POSITION.TOP_RIGHT
    })
  }

  async function handleGetUser() {
    const result = await getUser()

    setUser(result)
  }

  return {
    authenticated,
    loading,
    user,
    handleLogin,
    handleLogout
  }
}
