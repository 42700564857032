import axios from 'axios'
import QS from 'qs'

import { parseDataInterceptor } from './interceptors'

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_DOMAIN, // eslint-disable-line no-undef
  timeout: 30000,
  paramsSerializer: (params) => QS.stringify(params, {
    arrayFormat: 'brackets',
    encode: false
  })
})

apiClient.interceptors.response.use(parseDataInterceptor)

export default apiClient
