import React, { createContext } from "react"

import useAuth from './hooks/useAuth'

const Context = createContext()

const AuthProvider = ({ children }) => {
  const {
    authenticated, loading, user, handleLogin, handleLogout
  } = useAuth()

  return(
    <Context.Provider value={{ authenticated, loading, user, handleLogin, handleLogout }}>
      {children}
    </Context.Provider>
  )
}

export { Context, AuthProvider }
