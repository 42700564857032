import { CancelToken } from 'axios'

export const STUDENTS_ENDPOINT = '/students'

import apiClient from '@/api'
import { IParams, IStudentsRequest } from '@/@types/models'

const getAll = (params?: IParams, cancelToken?: CancelToken) => apiClient.get<IStudentsRequest>(STUDENTS_ENDPOINT, {
  params,
  cancelToken
})

export const StudentsService = {
  getAll
}
