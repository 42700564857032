import styled from "@emotion/styled"
import { colors } from "@mui/material"

import {
  MINIMUM_SCREEN_SIZE_DESKTOP,
  MINIMUM_SCREEN_SIZE_TABLET
} from "@/utils/constants"

export const Wrapper = styled.div`
  margin-bottom: 20px;
`

export const WrapperInfosResults = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 16px;

  @media (min-width: ${MINIMUM_SCREEN_SIZE_TABLET}px) {
    flex-direction: row;
  }
`

export const WrapperInfos = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column-reverse;
  margin-bottom: 20px;

  @media (min-width: ${MINIMUM_SCREEN_SIZE_TABLET}px) {
    align-items: flex-end;
    flex-direction: row;
    justify-content: space-between;
  }

  @media (min-width: ${MINIMUM_SCREEN_SIZE_DESKTOP}px) {
   justify-content: flex-start;
  }
`

export const Left = styled.div`
  width: 100%;

  @media (min-width: ${MINIMUM_SCREEN_SIZE_TABLET}px) {
    width: calc(50% - 8px);
  }

  @media (min-width: ${MINIMUM_SCREEN_SIZE_DESKTOP}px) {
    margin-right: 16px;
    max-width: 320px;
  }
`

export const Right = styled.div`
  margin-bottom: 16px;
  width: 100%;
  
  @media (min-width: ${MINIMUM_SCREEN_SIZE_TABLET}px) {
    margin-bottom: 0px;
    width: calc(50% - 8px);
  }

  @media (min-width: ${MINIMUM_SCREEN_SIZE_DESKTOP}px) {
    max-width: 250px;
  }
`

export const Title = {
  alignItems: 'flex-end',
  display: 'flex',
  color: colors.grey[500]
}

export const TitleCount = styled.span`
  color: ${colors.common.black};
  display: flex;
  font-size: 1rem;
  font-weight: bold;
  margin: 0 4px;
`

