import React from 'react'

import * as Styles from './LinkRoute.styles'

interface LinkProps {
  route: string;
  children: string;
}

const LinkRoute = ({ route, children }: LinkProps) => (
  <Styles.Navigator to={route}>
    {children}
  </Styles.Navigator>
)

export default LinkRoute
