import React from 'react'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'

import { Students } from '@/@types/models/Students'
import { SORTED_ASCENDING, SORTED_DESCENDING } from '@/utils/constants'
import * as Styles from './BasicTableHead.styles'

type Order = typeof SORTED_ASCENDING | typeof SORTED_DESCENDING;

interface BasicTableHeadProps {
  onRequestSort: (_event: React.MouseEvent<unknown>, _property: keyof Students) => void;
  order: Order;
  orderBy: string;
}

interface HeadCell {
  id: keyof Students;
  label: string;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'ra',
    label: 'RA'
  },
  {
    id: 'nome',
    label: 'NOME COMPLETO'
  },
  {
    id: 'saldo',
    label: 'SALDO'
  }
]

const BasicTableHead: React.FC<BasicTableHeadProps> = ({
  order,
  orderBy,
  onRequestSort
}) => {
  const createSortHandler = (property: keyof Students) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            style={Styles.TableCell}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : SORTED_ASCENDING}
              onClick={createSortHandler(headCell.id)}
              style={Styles.SortLabel}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default BasicTableHead
