import React from 'react'

import * as Styles from './TopPage.styles'

interface TopPageProps {
  children: React.ReactNode
  props?: any
}

const TopPage: React.FC<TopPageProps> = ({ children, ...props }) => (
  <Styles.Wrapper {...props}>
    {children}
  </Styles.Wrapper>
)

export default TopPage
