import styled from '@emotion/styled'
import { colors, Link, LinkProps } from '@mui/material'

export const HeaderLink = styled(Link)<LinkProps>`
  color: ${colors.grey[500]};
  text-decoration: none;
  transition-duration: 300ms;

  &:hover {
    color: ${colors.grey[700]};
  }
`
