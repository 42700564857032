import React from 'react'

import * as Styles from './AlertNotification.style'

type Props = {
  color: string,
  children: JSX.Element,
};

const AlertNotification = ({ color, children }: Props) => {
  return (
    <div>
      <Styles.AlertNotification color={color}>
        {children}
      </Styles.AlertNotification>
    </div>
  )
}

export default AlertNotification
