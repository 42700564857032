import * as React from 'react'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Link from '@mui/material/Link'
import MenuIcon from '@mui/icons-material/Menu'
import IconButton from '@mui/material/IconButton'

import * as Styles from './MenuDropdown.styles'

interface MenuDropdownProps {
  items: {
    title: string
    href: string
  }[]
  onLogout: () => void
}

const MenuDropdown: React.FC<MenuDropdownProps> = ({
  items = [],
  onLogout = () => undefined
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Styles.Wrapper>
      <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        {items.map(item => (
          <MenuItem
            key={item.title}
            onClick={handleClose}
          >
            <Link
              color="rgb(0,0,0)"
              href={item.href}
              sx={{ textDecoration: 'none' }}
              variant="body1"
            >
              {item.title}
            </Link>
          </MenuItem>
        ))}
        <MenuItem
          onClick={onLogout}
        >
          Sair
        </MenuItem>
      </Menu>
    </Styles.Wrapper>
  )
}

export default MenuDropdown
