import styled from '@emotion/styled'

import { MINIMUM_SCREEN_SIZE_TABLET } from '@/utils/constants'

export const Wrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;

  @media (min-width: ${MINIMUM_SCREEN_SIZE_TABLET}px) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
`

