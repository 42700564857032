import React from 'react'
import { LinkProps, Typography } from '@mui/material'

import * as Styles from './HeaderItem.styles'

const HeaderItem= (props: LinkProps) => (
  <Typography variant="body1">
    <Styles.HeaderLink
      sx={{ cursor: 'pointer' }}
      {...props}
    />
  </Typography>
)

export default HeaderItem
