import { useCallback, useEffect, useState } from 'react'
import { deserialise } from 'kitsu-core'
import axios from 'axios'
import { toast } from 'react-toastify'

const DEBOUNCE_TIME_MILLISECONDS = 500

const MINIMUM_VALUE_LENGTH = 3

import useDebounce from '@/Context/hooks/useDebounce'
import { StudentsService } from '@/services'
import { ERROR_GET_STUDENTS } from '@/utils/constants'

let cancelToken

export const useStudents = () => {
  const [meta, setMeta] = useState()

  const [page, setPage] = useState(0)

  const [pageAlreadyRequested, setPageAlreadyRequested] = useState([])

  const [students, setStudents] = useState([])

  const [search, setSearch] = useState('')

  const debounceSearch = useDebounce(search, DEBOUNCE_TIME_MILLISECONDS)

  const [displayResetSearchButton, setDisplayResetSearchButton] = useState(false)

  const [loading, setLoading] = useState(false)

  const setResults = useCallback((data) => {
    const deserializedData = deserialise(data)

    setStudents([
      ...students,
      ...deserializedData.data
    ])

    setMeta(deserializedData.meta)
  }, [students])

  const reset = () => {
    setStudents([])

    setPageAlreadyRequested([])

    setPage(0)

    setSearch('')

    setDisplayResetSearchButton(false)
  }

  const getAll = useCallback(async () => {
    const realPage = page + 1

    if(pageAlreadyRequested.includes(realPage)) return

    const params = {
      populate: '*',
      pagination: {
        page: realPage,
        pageSize: 500
      }
    }

    try {
      setLoading(true)

      const data = await StudentsService.getAll(params)

      setResults(data)

      if(!pageAlreadyRequested.includes(realPage)) {
        setPageAlreadyRequested([
          ...pageAlreadyRequested,
          realPage
        ])
      }
    } catch {
      toast.error(ERROR_GET_STUDENTS, {
        position: toast.POSITION.TOP_RIGHT
      })
    } finally {
      setLoading(false)
    }
  }, [
    page,
    pageAlreadyRequested,
    setResults
  ])

  const searchAll = useCallback(async () => {
    if (cancelToken) {
      cancelToken.cancel()
    }

    cancelToken = axios.CancelToken.source()

    const params = {
      populate: '*',
      pagination: {
        page: 0,
        pageSize: 50
      }
    }

    try {
      setLoading(true)

      setPage(0)

      const data = await StudentsService.getAll({
        ...params,
        filters: {
          $or: {
            0: {
              nome: {
                $containsi: debounceSearch
              }
            },
            1: {
              ra: {
                $containsi: debounceSearch
              }
            }
          }
        }
      }, cancelToken.token)

      const deserializedData = deserialise(data)

      setStudents(deserializedData.data)

      setMeta(deserializedData.meta)
    } catch(error) {
      toast.error(ERROR_GET_STUDENTS, {
        position: toast.POSITION.TOP_RIGHT
      })
    } finally {
      setLoading(false)
    }
  }, [debounceSearch])

  useEffect(() => {
    if(debounceSearch.length >= MINIMUM_VALUE_LENGTH) {
      setDisplayResetSearchButton(true)
      searchAll()
    }
  }, [debounceSearch, searchAll])

  return {
    displayResetSearchButton,
    getAll,
    loading,
    meta,
    page,
    reset,
    search,
    setDisplayResetSearchButton,
    setPage,
    setSearch,
    students
  }
}
