import { Box, Container, Button } from '@mui/material'
import React from 'react'

import * as Styles from './Deposito.styles'

const Deposito = () => {
  return (
    <Container>
      <Box marginTop={'20px'}>
        <Styles.Title>Adicionar crédito</Styles.Title>
        <Styles.Text>
          Você pode adicionar crédito para seu filho de duas maneiras:
        </Styles.Text>
      </Box>
      <Styles.Wrapper>
        <Styles.Box>
          <Styles.Title>1. Pix</Styles.Title>
          <Styles.Text>
            Primeiro faça um pix com o valor do depósito pra esse cpf:
          </Styles.Text>
          <Styles.Pix>947.149.758-20</Styles.Pix>
          <Styles.Text>
            Envie um comprovante por whatsapp dizendo o nome e o RA do
            aluno clicando aqui:
          </Styles.Text>
          <Button
            color="primary"
            size="large"
            variant="contained"
          >
            <Styles.Link target={'_blank'} href="https://wa.me/5511991467397">Enviar comprovante</Styles.Link>
          </Button>
          <Box marginBottom={'20px'} />
          <Styles.Text>
            Depois do envio do comprovante levamos até 24 horas para para aplicar o crédito na plataforma.
          </Styles.Text>
        </Styles.Box>
        <Styles.Box>
          <Styles.Title>2. Dinheiro</Styles.Title>
          <Styles.Text>
            Pague diretamente na cantina para adicionarmos o crédito na nossa plataforma
          </Styles.Text>
        </Styles.Box>
      </Styles.Wrapper>
    </Container>
  )
}

export default Deposito
