import React, { useEffect } from 'react'
import { Container, Box, Button } from '@mui/material'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-mui'
import { useLocation } from "react-router-dom"
import { AxiosError } from 'axios'
import { deserialise } from 'kitsu-core'
import { toast } from 'react-toastify'

const INVALID_TOKEN = 'Incorrect code provided'

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

import resetPasswordSchema from '@/utils/schemas/resetPasswordSchema'
import {
  ERROR_DEFAULT,
  INVALID_TOKEN_RESET_PASSWORD,
  SUCCESS_RESET_PASSWORD
} from '@/utils/constants'
import { resetPassword } from '@/api/auth'
import history from '@/history'
import ButtonBack from '@/components/Commons/Buttons/ButtonBack/ButtonBack'
import * as Styles from './ResetPassword.styles'

interface ResetPasswordValues {
  password: string
  passwordConfirmation: string
}

interface Error {
  error?: {
    message?: string
  }
}

const ResetPassword = () => {

  const initialValues: ResetPasswordValues = {
    password: '',
    passwordConfirmation: ''
  }

  const query = useQuery()

  useEffect(() => {
    if(!query.get('code')) {
      history.push('/login')

      return
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = async (values: ResetPasswordValues) => {
    const data = {
      ...values,
      code: query.get('code')
    }

    try {
      await resetPassword(data)

      toast.success(SUCCESS_RESET_PASSWORD, {
        position: toast.POSITION.TOP_RIGHT
      })

      history.push('/login')
    } catch(err) {
      const error = err as AxiosError

      const data: Error = deserialise(error.response?.data)

      if(data?.error?.message === INVALID_TOKEN) {
        toast.error(INVALID_TOKEN_RESET_PASSWORD, {
          position: toast.POSITION.TOP_RIGHT
        })

        return
      }

      toast.error(ERROR_DEFAULT, {
        position: toast.POSITION.TOP_RIGHT
      })
    }
  }

  return (
    <Container>
      <Box
        marginBottom={2}
        marginTop={4}
      >
        <ButtonBack />
      </Box>
      <Box>
        <Styles.Title>Resetar a senha</Styles.Title>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={resetPasswordSchema}
        >
          <Form>
            <Styles.WrapperForm>
              <Field
                width={100}
                component={TextField}
                label="Nova senha"
                name="password"
                type="password"
                variant="outlined"
              />
              <Field
                width={100}
                component={TextField}
                label="Confirmar nova senha"
                name="passwordConfirmation"
                type="password"
                variant="outlined"
              />
              <Button
                color="primary"
                size="large"
                type="submit"
                variant="contained"
              >
                Pronto
              </Button>
            </Styles.WrapperForm>
          </Form>
        </Formik>
      </Box>
    </Container>
  )
}

export default ResetPassword
