import apiClient from "."

export const STUDENTS_URL = "/orders"

export const getOrders = (params, cancelToken) => {
  return apiClient.get(STUDENTS_URL, {
    params,
    cancelToken
  })
}

export const postOrder = (params) => {
  return apiClient.post(STUDENTS_URL, {
    ...params
  })
}

export const deleteOrder = (id) => {
  return apiClient.delete(`${STUDENTS_URL}/${id}`)
}
