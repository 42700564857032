import React from 'react'
import { colors, Typography } from "@mui/material"

import * as Styles from './CardInfo.styles'

interface CardInfoProps {
  color: string
  icon: React.ReactNode
  info: number | undefined
  title: string
}

const CardInfo: React.FC<CardInfoProps> = ({
  color,
  icon,
  info,
  title,
  ...props
}) => (
  <Styles.Wrapper
    color={color}
    {...props}
  >
    <Styles.Icon color={color}>
      {icon}
    </Styles.Icon>
    <Styles.Right color={color}>
      <Typography
        color={colors.grey[400]}
        fontWeight="bold"
        variant="caption"
      >
        {title}
      </Typography>
      <Typography
        color={color}
        fontWeight="bold"
        variant="body1"
        display="flex"
      >
        {info}
      </Typography>
    </Styles.Right>
  </Styles.Wrapper>
)

export default CardInfo
