import styled from '@emotion/styled'
import { colors } from '@mui/material'

export const Wrapper = styled.div`
  align-items: center;
  background-color: ${colors.common.black}00075;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 99999999;
`
