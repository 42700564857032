import apiClient from "."

export const STUDENTS_URL = "/products"

export const getProducts = (params, cancelToken) => {
  return apiClient.get(STUDENTS_URL, {
    ...params,
    cancelToken
  })
}

