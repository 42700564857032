import React, { useContext } from 'react'
import { Container, Box, Button } from '@mui/material'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-mui'
import { AxiosError } from 'axios'
import { deserialise } from 'kitsu-core'
import { toast } from 'react-toastify'

const DOES_EXIST_EMAIL = 'This email does not exist'

import forgotPasswordSchema from '@/utils/schemas/forgotPasswordSchema'
import {
  ERROR_DEFAULT,
  SUCCESS_SEND_MAIL_FORGOT_PASS,
  DOES_EXIST_EMAIL as DOES_EXIST_EMAIL_PT
} from '@/utils/constants'
import { forgotPassword } from '@/api/auth'
import history from '@/history'
import ButtonBack from '@/components/Commons/Buttons/ButtonBack/ButtonBack'
import * as Styles from './ForgotPassword.styles'

interface ResetPasswordValues {
  email: string
}

interface Error {
  error?: {
    message?: string
  }
}

const ResetPassword = () => {

  const initialValues: ResetPasswordValues = { email: '' }

  const handleSubmit = async (values: ResetPasswordValues) => {
    try {
      await forgotPassword(values)

      toast.success(SUCCESS_SEND_MAIL_FORGOT_PASS, {
        position: toast.POSITION.TOP_RIGHT
      })

      history.push('/login')
    } catch(err) {
      const error = err as AxiosError

      const data: Error = deserialise(error.response?.data)

      if(data?.error?.message === DOES_EXIST_EMAIL) {
        toast.error(DOES_EXIST_EMAIL_PT, {
          position: toast.POSITION.TOP_RIGHT
        })

        return
      }

      toast.error(ERROR_DEFAULT, {
        position: toast.POSITION.TOP_RIGHT
      })
    }
  }

  return (
    <Container>
      <Box marginBottom={2}
        marginTop={4}>
        <ButtonBack />
      </Box>
      <Box>
        <Styles.Title>Resetar a senha</Styles.Title>
        <Styles.Subtitle>
          Digite seu e-mail e te enviaremos um link para resetar sua senha
        </Styles.Subtitle>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={forgotPasswordSchema}
        >
          <Form>
            <Styles.WrapperForm>
              <Field
                width={100}
                component={TextField}
                label="E-mail"
                name="email"
                type="email"
                variant="outlined"
              />
              <Button
                color="primary"
                size="large"
                type="submit"
                variant="contained"
              >
                Enviar e-mail
              </Button>
            </Styles.WrapperForm>
          </Form>
        </Formik>
      </Box>
    </Container>
  )
}

export default ResetPassword
