import styled from '@emotion/styled'

import { MINIMUM_SCREEN_SIZE_TABLET } from '@/utils/constants'

export const Wrapper = styled.div`
  display: flex;

  @media (min-width: ${MINIMUM_SCREEN_SIZE_TABLET}px) {
    display: none;
  }
`

