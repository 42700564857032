import React from 'react'

import LinkRoute from '@/components/Commons/LinkRoute/LinkRoute'
import { convertToReal } from '@/utils/services/money-service'
import AlertNotification from '@/components/Commons/AlertNotification/AlertNotification'
import * as Styles from './CardStudent.styles'

const CardStudent = ({ student }) => {
  return (
    <LinkRoute route={`/students/${student.ra}`}>
      <Styles.Card>
        <div>
          <Styles.CardTitle>{student.nome}</Styles.CardTitle>
          <Styles.CardSubtitle>RA: {student.ra}</Styles.CardSubtitle>
          <Styles.CardSaldo saldo={student.saldo}>
            Saldo: {student.saldo ? convertToReal(student.saldo) : '-'}
          </Styles.CardSaldo>
        </div>
        {student.saldo > 0 && (
          <AlertNotification color="#23C552">
            O estudante está com crédito na cantina
          </AlertNotification>
        )}
        {student.saldo < 0 && student.saldo > -100 && (
          <AlertNotification color="#F0C66A">
            O estudante está com débito na cantina
          </AlertNotification>
        )}
        {student.saldo < -100 && (
          <AlertNotification color="#F84F31">
            O estudante está com débito muito alto, por favor regularize-o
          </AlertNotification>
        )}
      </Styles.Card>
    </LinkRoute>
  )
}

export default CardStudent
