import styled from '@emotion/styled'

interface ColorProps {
  color: string
}

export const AlertNotification = styled.div<ColorProps>`
  background-color: ${({ color }) => color};
  padding: 20px;
  border-radius: 10px;
  color: #FFF;
  width: 375px;

  @media(max-width: 576px){
    width: 100%;
    margin-top: 30px;
  }
`
