import React, { useContext, useState } from 'react'
import Button from '@mui/material/Button'
import DeleteIcon from '@mui/icons-material/Delete'
import Dialog from '@mui/material/Dialog'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'

import { Context } from '@/Context/AuthContext'
import MenuList from './MenuList/MenuList'
import * as Styles from './MenuWrapper.style'

interface MenuWrapperProps {
  checkedItems: number[]
  displayDialogDelete: boolean
  editItems: number[]
  items: {
    id: number
    title: string
    day: string
  }[]
  onAddItem: (_data: {
    title: string
    day: string
  }) => void
  onDeleteItem: (_ids: number[], _type?: string) => undefined,
  onDisplayDialogDelete: (_display: boolean) => undefined
  onEditItem: () => undefined
  onToggleCheckedItem: () => undefined
}

const MenuWrapper: React.FC<MenuWrapperProps> = ({
  checkedItems = [],
  displayDialogDelete = false,
  editItems = [],
  items = [],
  onAddItem = () => undefined,
  onDeleteItem = () => undefined,
  onEditItem = () => undefined,
  onDisplayDialogDelete = () => undefined,
  onToggleCheckedItem = () => undefined
}) => {
  const { user } = useContext(Context)

  const [selectedIds, setSelectedIds] = useState<number[]>([0])

  const [value, setValue] = useState('')

  const [day, setDay] = useState('Segunda-feira')

  const handleToggleDialogDelete = (ids: number[], displayDialog: boolean): undefined => {
    onDisplayDialogDelete(displayDialog)

    setSelectedIds(ids)

    return
  }

  const handleOnDelete = (ids: number[]) => {
    onDisplayDialogDelete(false)

    onDeleteItem(ids)
  }

  const handleAddItem = (e: any) => {
    e.preventDefault()

    onAddItem({
      title: value,
      day: day
    })

    if(value) {
      setValue('')
    }
  }

  return (
    <>
      {(!!checkedItems.length && user?.role?.type == 'superadmin') && (
        <Button
          color="error"
          variant="outlined"
          onClick={() => handleToggleDialogDelete(checkedItems, true)}
        >
          <DeleteIcon />
          Apagar selecionado(s)
        </Button>
      )}
      <MenuList
        checkedItems={checkedItems}
        editItems={editItems}
        items={items}
        onEditItem={onEditItem}
        onToggleCheckedItem={onToggleCheckedItem}
        onToggleDialogDelete={handleToggleDialogDelete}
      />
      {user?.role?.type == 'superadmin' && (
        <Styles.Form onSubmit={handleAddItem}>
          <TextField
            type="text"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            size="small"
            placeholder="Adicionar item"
          />
          <Styles.WrapperDay>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Dia</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={day}
                label="Dia"
                onChange={(e) => setDay(e.target.value)}
                size="small"
              >
                <MenuItem value="Segunda-feira">Segunda-feira</MenuItem>
                <MenuItem value="Terça-feira">Terça-feira</MenuItem>
                <MenuItem value="Quarta-feira">Quarta-feira</MenuItem>
                <MenuItem value="Quinta-feira">Quinta-feira</MenuItem>
                <MenuItem value="Sexta-feira">Sexta-feira</MenuItem>
              </Select>
            </FormControl>
          </Styles.WrapperDay>

          <Button
            variant="contained"
            onClick={handleAddItem}
          >
            Adicionar item
          </Button>
        </Styles.Form>
      )}
      <Dialog
        maxWidth="xs"
        open={displayDialogDelete}
      >
        <Styles.DialogBody>
          <Typography variant="h6">
            Tem certeza que deseja apagar o(s) item(ns):
          </Typography>

          <Styles.Buttons>
            <Button
              color="inherit"
              onClick={() => handleToggleDialogDelete([0], false)}
              variant="outlined"
            >
              Cancelar
            </Button>
            <Button
              color="error"
              onClick={() => handleOnDelete(selectedIds)}
              variant="contained"
            >
              Confirmar
            </Button>
          </Styles.Buttons>
        </Styles.DialogBody>
      </Dialog>
    </>
  )
}

export default MenuWrapper
