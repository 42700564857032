import React from 'react'
import {
  ToggleButton,
  ToggleButtonGroup,
  TextField
} from '@mui/material'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import NumberFormat from 'react-number-format'

import BasicModal from '@/components/Commons/BasicModal/BasicModal'
import * as Styles from './PayModal.styles'

interface PayModalProps {
  displayModal: boolean
  loading: boolean
  onChangeCategory: () => void
  onChangeTypePay: () => void
  onChangeValue: (_value: number) => void
  onCloseModal: () => void
  onConfirm: () => void
  product: string
  products: {
    name: string
    id: number
  }[]
  typePay: string
  value: string
}

const PayModal: React.FC<PayModalProps> = ({
  displayModal = false,
  loading = false,
  onChangeTypePay = () => undefined,
  onChangeValue = () => undefined,
  onCloseModal = () => undefined,
  onChangeCategory = () => undefined,
  onConfirm = () => undefined,
  product = '',
  products = [],
  typePay
}) => {

  const currencyFormatter = (value: any) =>{
    if (!Number(value)) return ""

    const amount = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL"
    }).format(value / 100)

    return `${amount}`
  }

  const handleOnChange = (event: any) => {
    const value = event.target.value

    const replacedValue = value
      .replace('R$', '')
      .replaceAll('.', '')
      .replaceAll(',', '.')

    onChangeValue(Number(replacedValue))
  }

  return (
    <BasicModal
      display={displayModal}
      loading={loading}
      onClose={onCloseModal}
      onConfirm={onConfirm}
      title={`Adicionar ${typePay === 'Credit' ? 'crédito' : 'débito'}`}
    >
      <ToggleButtonGroup
        color="standard"
        exclusive
        onChange={onChangeTypePay}
        sx={{ marginBottom: 2 }}
        value={typePay}
      >
        <ToggleButton
          color="success"
          fullWidth
          value="Credit"
        >
          Crédito
        </ToggleButton>
        <ToggleButton
          color="warning"
          fullWidth
          value="Debit"
        >
          Débito
        </ToggleButton>
      </ToggleButtonGroup>
      <Styles.WrapperInputs onSubmit={onConfirm}>
        <Styles.Left>
          <NumberFormat
            customInput={TextField}
            defaultValue={''}
            disabled={false}
            format={currencyFormatter}
            label="Valor"
            onChange={handleOnChange}
            thousandSeparator={true}
            variant="outlined"
          />
        </Styles.Left>
        {typePay === 'Debit' ? (
          <Styles.Right>
            <FormControl fullWidth>
              <InputLabel id="product-select-label">
                Produto/Categoria
              </InputLabel>
              <Select
                id="product-select-label"
                label="Produto/Categoria"
                labelId="product-select-label"
                onChange={onChangeCategory}
                value={product}
                size="medium"
              >
                <MenuItem
                  value="select"
                >
                  Selecionar
                </MenuItem>
                {products.map((product, index) => (
                  <MenuItem
                    key={index}
                    value={product?.id}
                  >
                    {product?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Styles.Right>
        ) : null}
      </Styles.WrapperInputs>
    </BasicModal>
  )
}

export default PayModal
