import React, { useMemo } from 'react'
import { colors, Typography } from '@mui/material'

import MenuItem from './MenuItem/MenuItem'
import * as Styles from './MenuList.styles'

interface MenuWrapperProps {
  checkedItems: number[]
  editItems: number[]
  items: {
    id: number
    title: string
    day: string
  }[]
  onEditItem: () => undefined
  onToggleCheckedItem: () => undefined
  onToggleDialogDelete: (_id: number[], _displayDialog: boolean) => undefined
}

const MenuList: React.FC<MenuWrapperProps> = ({
  checkedItems = [],
  editItems = [],
  items = [],
  onEditItem = () => undefined,
  onToggleCheckedItem = () => undefined,
  onToggleDialogDelete = () => undefined
}) => {
  const segunda = useMemo(() => {
    return items.filter((item) => item.day === 'Segunda-feira')
  }, [items])

  const terca = useMemo(() => {
    return items.filter((item) => item.day === 'Terça-feira')
  }, [items])

  const quarta = useMemo(() => {
    return items.filter((item) => item.day === 'Quarta-feira')
  }, [items])

  const quinta = useMemo(() => {
    return items.filter((item) => item.day === 'Quinta-feira')
  }, [items])

  const sexta = useMemo(() => {
    return items.filter((item) => item.day === 'Sexta-feira')
  }, [items])

  return (
    <>
      <Typography
        variant="body1"
        color="#9e9e9e"
        fontWeight="semibold"
        sx={{ margin: 0 }}
      >
          Segunda-feira
      </Typography>
      <Styles.WrapperDay>
        <Styles.MenuList>
          {segunda.map((item) => (
            <MenuItem
              checkedItems={checkedItems}
              editItems={editItems}
              item={item}
              key={`${item.id}`}
              onEditItem={onEditItem}
              onToggleCheckedItem={onToggleCheckedItem}
              onToggleDialogDelete={onToggleDialogDelete}
            />
          ))}
          {segunda.length === 0 ? (
            <Typography
              color={colors.grey[500]}
              variant="body2"
              sx={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
          Nenhum item cadastrado ainda
            </Typography>
          ) : null}
        </Styles.MenuList>
      </Styles.WrapperDay>

      <Typography
        variant="body1"
        color="#9e9e9e"
        fontWeight="semibold"
        sx={{ margin: 0 }}
      >
          Terça-feira
      </Typography>
      <Styles.WrapperDay>
        <Styles.MenuList>
          {terca.map((item) => (
            <MenuItem
              checkedItems={checkedItems}
              editItems={editItems}
              item={item}
              key={`${item.id}`}
              onEditItem={onEditItem}
              onToggleCheckedItem={onToggleCheckedItem}
              onToggleDialogDelete={onToggleDialogDelete}
            />
          ))}
          {terca.length === 0 ? (
            <Typography
              color={colors.grey[500]}
              variant="body2"
              sx={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
          Nenhum item cadastrado ainda
            </Typography>
          ) : null}
        </Styles.MenuList>
      </Styles.WrapperDay>

      <Typography
        variant="body1"
        color="#9e9e9e"
        fontWeight="semibold"
        sx={{ margin: 0 }}
      >
        Quarta-feira
      </Typography>
      <Styles.WrapperDay>
        <Styles.MenuList>
          {quarta.map((item) => (
            <MenuItem
              checkedItems={checkedItems}
              editItems={editItems}
              item={item}
              key={`${item.id}`}
              onEditItem={onEditItem}
              onToggleCheckedItem={onToggleCheckedItem}
              onToggleDialogDelete={onToggleDialogDelete}
            />
          ))}
          {quarta.length === 0 ? (
            <Typography
              color={colors.grey[500]}
              variant="body2"
              sx={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
          Nenhum item cadastrado ainda
            </Typography>
          ) : null}
        </Styles.MenuList>
      </Styles.WrapperDay>

      <Typography
        variant="body1"
        color="#9e9e9e"
        fontWeight="semibold"
        sx={{ margin: 0 }}
      >
        Quinta-feira
      </Typography>
      <Styles.WrapperDay>
        <Styles.MenuList>
          {quinta.map((item) => (
            <MenuItem
              checkedItems={checkedItems}
              editItems={editItems}
              item={item}
              key={`${item.id}`}
              onEditItem={onEditItem}
              onToggleCheckedItem={onToggleCheckedItem}
              onToggleDialogDelete={onToggleDialogDelete}
            />
          ))}
          {quinta.length === 0 ? (
            <Typography
              color={colors.grey[500]}
              variant="body2"
              sx={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
          Nenhum item cadastrado ainda
            </Typography>
          ) : null}
        </Styles.MenuList>
      </Styles.WrapperDay>

      <Typography
        variant="body1"
        color="#9e9e9e"
        fontWeight="semibold"
        sx={{ margin: 0 }}
      >
        Sexta-feira
      </Typography>
      <Styles.WrapperDay>
        <Styles.MenuList>
          {sexta.map((item) => (
            <MenuItem
              checkedItems={checkedItems}
              editItems={editItems}
              item={item}
              key={`${item.id}`}
              onEditItem={onEditItem}
              onToggleCheckedItem={onToggleCheckedItem}
              onToggleDialogDelete={onToggleDialogDelete}
            />
          ))}
          {sexta.length === 0 ? (
            <Typography
              color={colors.grey[500]}
              variant="body2"
              sx={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
          Nenhum item cadastrado ainda
            </Typography>
          ) : null}
        </Styles.MenuList>
      </Styles.WrapperDay>
    </>
  )
}

export default MenuList
