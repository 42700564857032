import styled from '@emotion/styled'
import { colors } from '@mui/material'

export const Wrapper = styled.div`
`

export const Date = styled.small`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  color: ${colors.grey[400]};
`

export const NotResults = styled.p`
  display: flex;
  justify-content: center;
`
